<template>
	<div :class="data.additionalClass">
		<div class="percentageBlocks" :class="blocksClass">
			<div v-for="item in data">
				<div class="percentageBlocks--percent">
					<AnimatedNumber :to="item.percent"></AnimatedNumber> <span class="percentageBlocks--icon">%</span>
				</div>
				<div class="percentageBlocks--desc" v-html="item.desc"></div>
			</div>
		</div>
	</div>
</template>



<style lang="sass">
	@import '@/assets/sass/PercentageBlocks'
</style>

<script>
	import	AnimatedNumber from '@/components/app/AnimatedNumber'
	export default {
		props: ['data', 'blocksClass'],
		components: {
			AnimatedNumber
		}
	}
</script>
