<template>
	<div>
		<div class="dropDowns vacancies margin4">
			<DropDown @buttonClick="buttonClick" v-for="item in data" :title="item.title" :desc="item.salary" :content="item.content" :buttonText="'Откликнуться'" :value="item.title" :form="'vacancy'" :input="'vacancy'" :showHideButton="true"></DropDown>
		</div>
		<p><a @click="buttonClick('vacancy', 'Другая', 'vacancy')" class="redText">Отправьте резюме</a>, даже если в списке нет подходящей для вас вакансии.</p>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/Vacancies'
</style>

<script>
	import DropDown from '@/components/app/DropDown'
	export default {
		props: ['data'],
		components: {
			DropDown
		},
		methods: {
			buttonClick: function(input, value, formName) {
				if(input && value && formName){
					var form = document.forms[formName];
					if(form.elements[input].closest('.select')){
						var select = form.elements[input].closest('.select');
						if(select.querySelectorAll("[data-value='"+ value +"']").length){
							select.querySelectorAll("[data-value='"+ value +"']")[0].click();
						}
					}
				}
				if(formName){
					var form = document.forms[formName];
					if(form.closest('.formBlock')){
						doScrolling(form.closest('.formBlock').offsetTop, 400);
					}
				}
			}
		}
	}
</script>