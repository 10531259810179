<template>
	<div v-if="data.type == 'type1'">
		<div class="siteScreensContainer">
			<VuePerfectScrollbar class="siteScreens" :class="data.type" :settings="scrollSettings">
				<img :src="data.desktopImage" v-if="data.desktopImage" alt="" class="siteScreens--desktop">
				<img :src="data.tabletImage" v-if="data.tabletImage" alt="" class="siteScreens--tablet">
				<div class="siteScreens__mobile" v-if="data.mobileImageInner && data.mobileImage">
					<div class="siteScreens__mobileInner">
						<img :src="data.mobileImageInner" v-if="data.mobileImageInner" alt="" class="siteScreens--mobileInner" ref="phone" :style="{'top': '-' + phonePos + 'px'}">
					</div>
					<img :src="data.mobileImage" v-if="data.mobileImage" alt="" class="siteScreens--mobilePhone">
				</div>
				<img :src="data.mobileImage" v-else-if="data.mobileImage" alt="" class="siteScreens--mobile">
				<img :src="data.personImage" v-if="data.personImage" alt="" class="siteScreens--person">
			</VuePerfectScrollbar>
		</div>
	</div>
	<div class="siteScreens" :class="data.type" v-else>
		<img :src="data.desktopImage" v-if="data.desktopImage" alt="" class="siteScreens--desktop">
		<img :src="data.tabletImage" v-if="data.tabletImage" alt="" class="siteScreens--tablet">
		<img :src="data.mobileImage" v-if="data.mobileImage" alt="" class="siteScreens--mobile">
		<img :src="data.personImage" v-if="data.personImage" alt="" class="siteScreens--person">
	</div>
</template>

<style lang="sass">
	@import '@/assets/sass/case/SiteScreens'
</style>

<script>
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'

	export default {
		props: ['data'],
		data: () => ({
			phonePos: 0,
			scrollSettings: {
				suppressScrollY: true,
				minScrollbarLength: 80,
				maxScrollbarLength: 80
			},
		}),
		methods: {
			phone: function(){
				var scrollTop = document.documentElement.scrollTop;
				var windowHeight = window.innerHeight;
				var windowWidth = window.innerWidth;
				var windowBottomPos = scrollTop + windowHeight;
				var element = this.$refs["phone"];
				var elementHeight = element.offsetHeight;
				var elementTopPos = element.getBoundingClientRect().top + document.documentElement.scrollTop;
				var elementBottomPos = elementTopPos + elementHeight;
				var parent = element.closest('.siteScreens__mobileInner');
				var parentHeight = parent.offsetHeight;

				if(windowBottomPos > elementTopPos && scrollTop < elementBottomPos){
					var percent = 1 - ((elementBottomPos - scrollTop) / (windowHeight + elementHeight));
					this.phonePos = (elementHeight - parentHeight) * percent;
				}
			}
		},
		mounted(){
			var comp = this;
			if(this.data.mobileImageInner && this.data.mobileImage){
				window.addEventListener('scroll', function() {
					comp.phone();
				});
				window.addEventListener('resize', function() {
					comp.phone();
				});
			}
		},
		components: {
			VuePerfectScrollbar
		},
	}
</script>