<template>
	<div>
		<template v-for="block in data">
			<img v-if="block.image && block.component == 'image'" v-lazy="block.image" :class="block.additionalClass ? block.additionalClass : ''" alt="">

			<component v-if="block.text && block.component == 'text'" :class="block.additionalClass ? block.additionalClass : ''" :is="{template: '<div>' + block.text + '</div>'}"></component>

			<AboutUsInNumbers v-if="block.component == 'aboutUsInNumbers'" :data="block.data" :additionalClass="block.additionalClass ? block.additionalClass : ''"></AboutUsInNumbers>

			<Advantages v-if="block.component == 'advantages'" :data="block.data" :columns="block.columns" :sliderInMobile="block.sliderInMobile"></Advantages>

			<Rating v-if="block.component == 'rating'" :data="block.data"></Rating>
		</template>
	</div>
</template>



<script>
	import AboutUsInNumbers from '@/components/app/AboutUsInNumbers'
	import Advantages from '@/components/app/Advantages'
	import Rating from '@/components/app/Rating'
	export default {
		props: ['data'],
		components: {
			AboutUsInNumbers,
			Advantages,
			Rating
		}
	}
</script>