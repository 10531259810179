<template>
	<div class="photoScrollBlocks">
		<div class="photoScrollBlocks__blocks">
			<PhotoScroll
				v-for="(block, index) in data" 
				:data="block.data" 
				:class="block.additionalClass"
				:title="block.title"
				:secondTitle="block.secondTitle"
				:secondDesc="block.secondDesc"
				:clickable="clickable"
				v-if="index < showedQuantity">
			</PhotoScroll>
		</div>

		<a @click="showAll" class="redText photoScrollBlocks--showAll">Показать сертификаты еще {{quantity - showQuantity}} сотрудников</a>
	</div>
</template>



<style lang="sass">
	@import '@/assets/sass/PhotoScrollBlocks'
</style>

<script>
	import PhotoScroll from '@/components/app/PhotoScroll'
	export default {
		props: ['data', 'showQuantity', 'clickable'],
		data: () => ({
			showedQuantity: 0,
			quantity: 0
		}),
		components: {
			PhotoScroll
		},
		beforeMount(){
			this.showedQuantity = this.showQuantity;
			this.quantity = this.data.length;
		},
		methods: {
			showAll: function(e){
				var parent = e.target.closest('.photoScrollBlocks').getElementsByClassName("photoScrollBlocks__blocks")[0];

				var beforeHeight = parent.offsetHeight;
				parent.style.height = beforeHeight + "px";
				this.showedQuantity = this.quantity;

				setTimeout(function(){
					var width = parent.offsetWidth;
					var clone = document.createElement("div");
					clone.style.height = "auto";
					clone.style.position = "absolute";
					clone.style.top = "0";
					clone.style.left = "0";
					clone.style.visibility = "hidden";
					clone.style.width = width + "px";
					parent.classList.forEach(function(className, index) {
						clone.classList.add(className);
					});
					clone.classList.add('fakeElement');
					clone.innerHTML = parent.innerHTML;

					document.body.appendChild(clone);
					var height = document.body.getElementsByClassName('fakeElement')[0].offsetHeight;
					var el = document.body.getElementsByClassName('fakeElement')[0];
					el.parentNode.removeChild(el);


					parent.style.height = height + "px";

					setTimeout(function(){
						if(parent.style.height == height + "px"){
							parent.style.height = "auto";
						}
					}, 400);

					e.target.style.display = 'none';
				}, 1);
			}
		}
	}
</script>