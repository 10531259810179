<template>
	<div class="tabs">
		<div class="tabs__header" v-if="!category && !type && !support && !cms && groupQuantity > 1">
			<span class="tabs--progress" :style="{ left: progressPos + 'px', width: progressWigth + 'px' }"></span>
			<a class="tabs__header__item" v-for="(item, index) in grouped" :data-tab="index" :class="{active: activeTab == index}" :href="$route.path + '?tab=' + index" @click.prevent="setTab($event, true)">
				{{index}}
			</a>
		</div>
		<div class="tabs__body">
			<transition-group name="slide" tag="div">
				<div class="tabs__body__item" v-for="(item, index) in grouped" :key="index" v-if="activeTab == index" :data-tab="index">
					<div class="projects">
						<div class="project" v-for="project in (showPagination ? item.slice(start, end) : item.slice(0, viewQuantity))" :class="[{ big: project.big && !smallElements, imageHover: project.imageHover, imageHoverSlide: project.imageHoverSlide, videoHover: project.videoHover, notLoop: project.videoNotLoop}, project.additionalClass]" @mouseenter="mouseEnter($event)" @mouseleave="mouseLeave($event)" @mousemove="mouseMove($event)">
							<a :href="project.link" v-if="project.link && project.linkInRouter" @click="goToCase($event, project.link)"></a>
							<a :href="project.link" v-else-if="project.link"></a>
							<span class="project__img" v-if="project.imageSrc" v-lazy:background-image="project.imageSrc">
								<span class="project--imageHoverSlide" v-if="project.imageHoverSlideSrc">
									<span class="project--imageHoverSlide--image" v-lazy:background-image="project.imageHoverSlideSrc"></span>
								</span>
							</span>
							<span class="project__img" v-else-if="project.icon" :style="{background: project.background}">
								<span :class="project.icon"></span>
							</span>
							<span class="project__img svgCode" v-else-if="project.svgCode" v-html="project.svgCode" :style="{background: project.background}"></span>

							<span class="project--imageHover" v-if="project.imageHoverSrc" v-lazy:background-image="project.imageHoverSrc"></span>
							<span class="project--videoHover" v-if="project.videoHover">
								<video :src="project.videoHoverSrc" muted="" autoplay="" playsinline="" :loop="!project.videoNotLoop"></video>
							</span>

							<div class="project--notCase" v-if="!project.link">
								<span>
									<span class="icon-castle"></span>
									<p>Кейс в разработке</p>
								</span>
							</div>
							
							<span class="project__info">
								<p class="project--title">{{project.title}}</p>
								<p class="project--text" v-if="support && project.supportText">{{project.supportText}}</p>
								<p class="project--text" v-else>{{project.text}}</p>
							</span>
							<img class="project--fullImage" :src="project.fullImageSrc">
						</div>
						<router-link to="/portfolio/" class="project" v-if="item.length > viewQuantity && !showPagination">
							<span class="project__img">
								<span class="icon-plus"></span>
							</span>
							<span class="project__info">
								<p class="project--title">Все проекты</p>
								<p class="project--text">в нашем портфолио</p>
							</span>
						</router-link>
					</div>
					<div class="paginationBlock" v-if="showPagination && pages > 1">
						<a class="showMore" @click="showMore(true)" v-if="page < pages">Показать еще</a>
						<div v-else></div>
						<ul class="pagination">
							<li v-for="i in pages">
								<a @click="setPage(i, true, true)" :href="$route.path + '?tab='+ activeTab +'&page=' + i" @click.prevent :class="{active: page == i}">{{i}}</a>
							</li>
						</ul>
					</div>
				</div>
			</transition-group>
		</div>
	</div>
</template>



<style lang="sass">
	@import '@/assets/sass/Projects'
</style>

<script>
	function groupBy(array, key){
		const result = {}
		array.forEach(item => {
			if (!result[item[key]]){
				result[item[key]] = []
			}
			result[item[key]].push(item)
		})
		return result
	}

	export default {
		props: ['data', 'quantity', 'quantityTable', 'quantityMobile', 'showPagination', 'category', 'smallElements', 'type', 'support', 'cms'],
		data: () => ({
			grouped: null,
			groupQuantity: 0,
			activeTab: null,
			progressWigth: null,
			progressPos: null,
			viewQuantity: 0,
			paginationTemplate: '',
			fullQuantity: 0,
			pages: 0,
			start: 0,
			end: 0,
			page: 1,
			projects: [],
			mpkabel: {
				line2Interval: null,
			}
		}),
		methods: {
			groupProjects: function(){
				this.grouped = groupBy(this.projects, 'type');
				this.groupQuantity = Object.keys(this.grouped).length;
				var activeTab = Object.keys(this.grouped)[0];
				if(this.$route.query.tab){
					activeTab = this.$route.query.tab;
				}

				this.activeTab = activeTab;
				this.fullQuantity = this.grouped[this.activeTab].length;
			},
			setTab: function(event, setUrl){
				this.activeTab = event.target.dataset.tab;
				this.progressWigth = event.target.offsetWidth;
				this.progressPos = event.target.offsetLeft;

				this.fullQuantity = this.grouped[this.activeTab].length;

				this.setQuantity();

				this.setPage(1, false, false);
				this.videoHeight();
			},
			setQuantity: function(){
				if(window.innerWidth <= 600){
					this.viewQuantity = this.quantityMobile;
					this.pages = Math.ceil(this.fullQuantity / this.quantityMobile);
				} else if(window.innerWidth <= 1100){
					this.viewQuantity = this.quantityTable;
					this.pages = Math.ceil(this.fullQuantity / this.quantityTable);
				} else{
					this.viewQuantity = this.quantity;
					this.pages = Math.ceil(this.fullQuantity / this.quantity);
				}
			},
			setPage: function(page, scrollTop, setUrl){
				if(scrollTop){
					doScrolling(document.getElementsByClassName("projects")[0].offsetTop, 400);
				}
				this.page = page;
				if(window.innerWidth <= 600){
					this.start = (page - 1) * this.quantityMobile;
					this.end = (page * this.quantityMobile > this.fullQuantity) ? this.fullQuantity : page * this.quantityMobile;
				} else if(window.innerWidth <= 1100){
					this.start = (page - 1) * this.quantityTable;
					this.end = (page * this.quantityTable > this.fullQuantity) ? this.fullQuantity : page * this.quantityTable;
				} else{
					this.start = (page - 1) * this.quantity;
					this.end = (page * this.quantity > this.fullQuantity) ? this.fullQuantity : page * this.quantity;
				}
				this.videoHeight();
			},
			showMore: function(setUrl){
				this.page = this.page + 1;
				if(window.innerWidth <= 600){
					this.end = (this.end + this.quantityMobile > this.fullQuantity) ? this.fullQuantity : this.end + this.quantityMobile;
				} else if(window.innerWidth <= 1100){
					this.end = (this.end + this.quantityTable > this.fullQuantity) ? this.fullQuantity : this.end + this.quantityTable;
				} else{
					this.end = (this.end + this.quantity > this.fullQuantity) ? this.fullQuantity : this.end + this.quantity;
				}
				this.videoHeight();
			},
			goToCase: function(e, link){
				e.preventDefault();
				var comp = this;

				var element = e.srcElement.closest('.project');
				var imageBlock = element.getElementsByClassName("project__img")[0];
				var fullImage = element.getElementsByClassName("project--fullImage")[0];

				const rect = imageBlock.getBoundingClientRect();
				var top = rect.top + window.pageYOffset;
				var left = rect.left + window.pageXOffset;
				var width = imageBlock.offsetWidth;
				var height = imageBlock.offsetHeight;
				var imageUrl = fullImage.attributes.src.value;

				var div = document.createElement("div");
				div.style.height = height + "px";
				div.style.width = width + "px";
				div.style.left = left + "px";
				div.style.top = top + "px";
				div.style.backgroundImage = "url('"+ imageUrl +"')";
				document.body.appendChild(div);
				div.classList.add('project--goTo');

				setTimeout(function(){
					div.classList.add('animate');
					doScrolling(0, 400);
				}, 100);
				setTimeout(function(){
					comp.$router.push(link);
					setTimeout(function(){
						div.classList.add('animateEnd');
						setTimeout(function(){
							div.remove();
						}, 600);
					}, 800);
				}, 500);
			},
			videoHeight: function(){
				var comp = this;
				setTimeout(function(){
					var videoContainers = comp.$el.getElementsByClassName("project--videoHover");
					
					Array.from(videoContainers).forEach(function(el, index){
						var video = el.getElementsByTagName("video")[0];
						video.style.height = 'auto';
						video.style.width = 'auto';

						var containerHeight = el.offsetHeight;
						var containerWidth = el.offsetWidth;
						var videoHeight = video.offsetHeight;
						var videoWidth = video.offsetWidth;

						if(containerHeight/containerWidth > containerWidth/videoWidth){
							video.style.height = (containerHeight+10) + "px";
						} else{
							video.style.width = (containerWidth+10) + "px";
						}
					});
				});
			},
			mouseEnter: function(event){
				this.videoHeight();
				var target = event.target;
				var element;
				if(target.classList.contains('mpkabel') || (target.closest('.project').length && target.closest('.project').classList.contains('mpkabel'))){
					if(target.classList.contains('mpkabel')){
						element = target;
					} else{
						element = target.closest('.project');
					}
					element.classList.remove("animateEnd");
					setTimeout(function(){
						element.classList.add("animate");
					}, 600);
				}
				if(target.classList.contains('notLoop') || (target.closest('.project').length && target.closest('.project').classList.contains('notLoop'))){
					if(target.classList.contains('mpkabel')){
						element = target;
					} else{
						element = target.closest('.project');
					}
					var video = element.getElementsByTagName("video")[0];
					video.currentTime = 0;
					video.play();
				}
			},
			mouseLeave: function(event){
				var target = event.target;
				var element;
				if(target.classList.contains('mpkabel') || (target.closest('.project').length && target.closest('.project').classList.contains('mpkabel'))){
					if(target.classList.contains('mpkabel')){
						element = target;
					} else{
						element = target.closest('.project');
					}
					setTimeout(function(){
						element.classList.add("animateEnd");
					}, 100);
					setTimeout(function(){
						element.classList.remove("animate");
					}, 600);
				}
			},
			mouseMove: function(event){
				var target = event.target;
				var element;
				if(target.classList.contains('imageHoverSlide') || (target.closest('.project') && target.closest('.project').classList.contains('imageHoverSlide'))){
					if(target.classList.contains('imageHoverSlide')){
						element = target;
					} else{
						element = target.closest('.project');
					}

					var image = element.getElementsByClassName("project__img")[0];
					var imageSlide = element.getElementsByClassName("project--imageHoverSlide")[0];

					var mousePos = event.pageY;

					var bodyRect = document.body.getBoundingClientRect(),
					elemRect = element.getBoundingClientRect();
					var blockTop = elemRect.top - bodyRect.top;

					var height = image.offsetHeight;

					var result = (mousePos - blockTop) / height * 100;
					imageSlide.style.height = result + "%";
				}
			}
		},
		watch: {
			'viewQuantity': function(){
				var page = 1;
				if(this.$route.query.page){
					page = parseInt(this.$route.query.page);
				}
				if(page > this.pages || page < 1){
					page = 1;
				}
				this.setPage(page, false, false);
			}
		},
		beforeMount(){
			var comp = this;
			this.data.forEach(function(item) {
				if(item.image){
					item.imageSrc = require(`@/assets/img/${item.image}`);
				}
			});
			this.data.forEach(function(item) {
				if(item.fullImage){
					item.fullImageSrc = require(`@/assets/img/${item.fullImage}`);
				}
			});
			this.data.forEach(function(item) {
				if(item.imageHover){
					item.imageHoverSrc = require(`@/assets/img/${item.imageHover}`);
				}
			});
			this.data.forEach(function(item) {
				if(item.videoHover){
					item.videoHoverSrc = require(`@/assets/img/${item.videoHover}`);
				}
			});
			this.data.forEach(function(item) {
				if(item.imageHoverSlide){
					item.imageHoverSlideSrc = require(`@/assets/img/${item.imageHoverSlide}`);
				}
			});
			this.data.forEach(function(item, key) {
				if(item.link){
					comp.$router.options.routes.forEach( function(link) {
						if(item.link == link.path && !link.meta.noRouter){
							comp.data[key].linkInRouter = "Y";
						}
					});
				}
			});
		},
		mounted() {
			var comp = this;
			window.addEventListener('resize', function(event){
				comp.setQuantity();
			});

			if(comp.category){
				comp.data.forEach(function(element) {
					if(element.category){
						if(element.category == comp.category){
							comp.projects.push(element);
						}
					}
				});
			} else if(comp.cms){
				comp.data.forEach(function(element) {
					if(element.cms){
						if(element.cms == comp.cms){
							comp.projects.push(element);
						}
					}
				});
			} else if(comp.type){
				comp.data.forEach(function(element) {
					if(element.type){
						if(element.type == comp.type){
							comp.projects.push(element);
						}
					}
				});
			} else if(comp.support){
				comp.data.forEach(function(element) {
					if(element.support){
						if(element.support){
							comp.projects.push(element);
						}
					}
				});
			} else{
				comp.projects = this.data;
				this.$nextTick(function () {
					if(this.$el.getElementsByClassName("tabs__header__item active").length){
						var activeElement = this.$el.getElementsByClassName("tabs__header__item active")[0];
						this.progressWigth = activeElement.offsetWidth;
						this.progressPos = activeElement.offsetLeft;
					}
				});
			}
			this.groupProjects();

			this.setQuantity();

			this.videoHeight();

			var page = 1;
			if(this.$route.query.page){
				page = parseInt(this.$route.query.page);
			}
			if(page > this.pages || page < 1){
				page = 1;
			}
			this.setPage(page, false, false);

			window.addEventListener('resize', function(event){
				comp.videoHeight();
			});
		}
	}
</script>