<template>
	<div>
		<VueSlickCarousel class="imageWithTextSlider" v-if="isMobile && sliderInMobile" v-bind="slickSettings">
			<div class="imageWithText" v-for="item in data">
				<div class="imageWithText__img">
					<img v-lazy="item.image" alt="">
				</div>
				<p class="bold fs20" v-html="item.title" :class="{mb6: item.text}"></p>
				<p v-html="item.text" :style="{'color': item.textColor}"></p>
			</div>
			<template #customPaging="page">
				<div class="dot2"></div>
			</template>
		</VueSlickCarousel>
		<div :class="(columns == 4) ? 'imageWithText--fourCol': ''" v-else>
			<div class="imageWithText" v-for="item in data">
				<div class="imageWithText__img">
					<img v-lazy="item.image" alt="">
				</div>
				<p class="bold fs20" v-html="item.title" :class="{mb6: item.text}"></p>
				<p v-html="item.text" :style="{'color': item.textColor}"></p>
			</div>
		</div>
	</div>
</template>



<style lang="sass">
	@import '@/assets/sass/images/ImageWithText'
</style>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	export default {
		props: ['data', 'columns', 'sliderInMobile'],
		data: () => ({
			slickSettings: {
				dots: true,
				arrows: false,
				dotsClass: 'imageWithTextSlider__dots',
				infinite: true,
				variableWidth: true
			},
			isMobile: null
		}),
		methods: {
			setIsMobile: function(){
				if(window.innerWidth > 600){
					this.isMobile = false;
				} else{
					this.isMobile = true;
				}
			}
		},
		mounted(){
			var comp = this;
			window.addEventListener('resize', function(event){
				comp.setIsMobile();
			});
			comp.setIsMobile();
		},
		components: {
			VueSlickCarousel
		}
	}
</script>