<template>
	<div>
		<p class="fs20 bold" :class="{margin4: !data.desc, mb6: data.desc}" v-html="data.title" v-if="data.title"></p>
		<p class="margin4 lh28" v-html="data.desc" v-if="data.desc"></p>

		<VuePerfectScrollbar class="imagesContainer" :settings="scrollSettings" v-if="data.withScroll">
			<div class="images" :class="data.imagesBlockClass">
				<img v-for="image in data.images" v-lazy="image.image" class="vat">
			</div>
		</VuePerfectScrollbar>

		<div class="images" :class="data.imagesBlockClass" v-else>
			<img v-for="image in data.images" v-lazy="image.image" class="vat">
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/images/Images'
</style>

<script>
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'

	export default {
		props: ['data'],
		data: () => ({
			scrollSettings: {
				suppressScrollY: true,
				minScrollbarLength: 80,
				maxScrollbarLength: 80
			}
		}),
		components: {
			VuePerfectScrollbar
		},
	}
</script>