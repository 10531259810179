<template>
	<ul :class="listType">
		<li v-for="item in data" v-html="item.text"></li>
	</ul>
</template>


<style lang="sass">
	@import '@/assets/sass/List'
</style>


<script>
	export default {
		props: ['listType', 'data'],

	}
</script>