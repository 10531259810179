<template>
	<div class="content">
		<WithLeftMenu :blocks="withLeftMenuContent"></WithLeftMenu>
		<FeedBack></FeedBack>
	</div>
</template>

<script>
	import	FeedBack from '@/components/app/forms/FeedBack'
	import	WithLeftMenu from '@/components/app/WithLeftMenu'
	import	Advantages from '@/components/app/Advantages'
	export default {
		metaInfo: {
			title: 'Услуги веб-студии | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Услуги веб-студии Артрокетс в Казани. Профессиональная разработка сайтов, техническая поддержка и поисковое продвижение, а также внедрение CRM 1С Битрикс. Заполните форму на сайте, и мы вам перезвоним!' },
				{ vmid: 'og:title', property: 'og:title', content: 'Услуги веб-студии | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Услуги веб-студии Артрокетс в Казани. Профессиональная разработка сайтов, техническая поддержка и поисковое продвижение, а также внедрение CRM 1С Битрикс. Заполните форму на сайте, и мы вам перезвоним!' },
			],
		},
		data: () => ({
			withLeftMenuContent: [
				{
					component: 'development',
					name: 'Разработка',
					link: '/uslugi/razrabotka/',
					data: [
						{
							component: 'imageSlider',
							additionalClass: 'margin2',
							data: [
								{
									image: require(`@/assets/img/development/1.jpg`),
								},{
									image: require(`@/assets/img/development/2.jpg`),
								},{
									image: require(`@/assets/img/development/3.jpg`),
								},{
									image: require(`@/assets/img/development/4.jpg`),
								},{
									image: require(`@/assets/img/development/5.jpg`),
								}
							]
						},{
							component: 'advantages',
							columns: 2,
							sliderInMobile: false,
							data: [
								{
									title: 'Корпоративные сайты',
									text: 'Авторский дизайн, повышающий имидж. Удобная навигация и интуитивно понятный функционал. Продуманный контент.',
									link: '/uslugi/korp-site/'
								},{
									title: 'Интернет-магазины',
									text: 'Структурированный каталог. “Умный” фильтр и быстрая сортировка товаров. Удобный функционал оформления заказа. Интеграция с 1С.',
									link: '/uslugi/internet-magazin/'
								},{
									title: 'Личные кабинеты',
									text: 'Автоматизация бизнес-процессов и вычислений, путем интеграции с внешними сервисами. Написание собственного API обмена для синхронизации с другими системами.',
								},{
									title: 'Highload проекты',
									text: 'Высоконагруженные сайты, порталы и сервисы-агрегаторы. Проекты, выдерживающие многомиллионные посещения.',
								}
							]
						}
					]
				},{
					component: 'advantages',
					name: 'Техническая поддержка',
					link: '/uslugi/support/',
					columns: 2,
					sliderInMobile: false,
					data: [
						{
							title: 'Редизайн',
							text: 'Изменение дизайна для улучшения поведенческих факторов и увеличения конверсии.'
						},{
							title: 'Доработка сайта на 1С-Битрикс',
							text: 'Исправление багов, доработка задач, модернизация действующего проекта на CMS 1С-Битрикс, в т.ч. разработанного не нами.'
						},{
							title: 'Интеграция с 1С',
							text: 'Синхронизация номенклатуры, остатков и заказов с сайтом.'
						},{
							title: 'Поддержка 24 на 7',
							text: 'Мониторинг сервера в постоянном режиме 24 часа в сутки, в выходные и праздничные дни.'
						}
					]
				},{
					component: 'advantages',
					name: 'Поисковое продвижение',
					//link: '/uslugi/seo/',
					columns: 2,
					sliderInMobile: false,
					data: [
						{
							title: 'Продвижение по трафику',
							text: 'Комплексная работа над сайтом для увеличения посещаемости ресурса из органической выдачи.'
						},{
							title: 'SEO-аудит и разовые работы ',
							text: 'Технический анализ, текстовый аудит, юзабилити аудит, исследование коммерческих факторов и конкурентов. Подготовка отчетов и рекомендаций.'
						},{
							title: 'Продвижение корпоративного сайта',
							text: 'Комплекс мероприятий для продвижения компании как бренда. Повышение видимости в интернете и укрепление доверия аудитории.'
						},{
							title: 'Продвижение интернет-магазина',
							text: 'Оптимизация каталога, согласно максимально возможной семантике ниши. Соблюдение коммерческих факторов, предъявляемых поисковыми системами к e-commerce проектам.'
						}
					]
				},{
					component: 'advantages',
					name: 'CRM<br> Битрикс 24',
					columns: 2,
					sliderInMobile: false,
					data: [
						{
							title: 'Облачный Битрикс24',
							text: 'Регистрация облачного портала и настройка бизнес процессов.'
						},{
							title: 'Установка коробочного Битрикс 24',
							text: 'Развертывание коробочного портала на сервере. Настройка необходимых бизнес процессов.'
						},{
							title: 'Доработка Битрикс24',
							text: 'Расширение и развитие стандартного функционала под требования заказчика.'
						}
					]
				} 
			]
		}),
		name: 'uslugi',
		components: {
			FeedBack,
			WithLeftMenu,
			Advantages
		}
	}
</script>
