<template>
	<div class="graph" :class="[data.additionalClass, {animate: animate}]">
		<div class="graphContainer"">
			<div class="graphImage" :style="{backgroundImage: 'url(' + data.graphImage + ')'}"></div>
			<img :src="data.graphImage">
			<div class="graphLines">
				<div v-for="line in data.labels" :class="{graphLine: line.withLine}">
					<div class="graphLineLogo" v-html="line.logo" v-if="line.logo"></div>
				</div>
			</div>
		</div>
		<div class="graphLabels">
			<div class="graphLabel" v-for="label in data.labels" :class="{withLine: label.withLine}" v-html="label.name"></div>
		</div>
	</div>
</template>

<style lang="sass">
	@import '@/assets/sass/Graph'
</style>

<script>
	export default {
		props: ['data'],
		data: () => ({
			animate: false
		}),
		methods: {
			animation: function(){
				var graph = this.$el;

				var bodyRect = document.body.getBoundingClientRect(),
				elemRect = graph.getBoundingClientRect();
				var top = elemRect.top - bodyRect.top;

				var height = graph.offsetHeight;
				var scrollTop = window.pageYOffset;
				var windowHeight = window.innerHeight;

				if(scrollTop + windowHeight >= top && scrollTop < top + height){
					this.animate = true;
				} else{
					this.animate = false;
				}
			}
		},
		created: function () {
			window.addEventListener('scroll', this.animation);
			window.addEventListener('resize', this.animation);
		},
		destroyed: function () {
			window.removeEventListener('scroll', this.animation);
			window.removeEventListener('resize', this.animation);
		}
	}
</script>