<template>
	<div class="youtube">
		<iframe src="https://www.youtube.com/embed/jo4A4Wqlksc" frameborder="0" allowfullscreen></iframe>
	</div>
</template>

<style lang="sass">
	@import '@/assets/sass/Youtube'
</style>
<script>
	export default {
		props: ['data']
	}
</script>