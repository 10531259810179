<template>
	<div class="contentBlockWithImage">
		<div class="contentBlockWithImage__content">
			<ContentBlock :data="data.data"></ContentBlock>
		</div>
		<div class="contentBlockWithImage__image">
			<img v-lazy="data.image">
		</div>
	</div>
</template>

<style lang="sass">
	@import '@/assets/sass/ContentBlockWithImage'
</style>

<script>
	import ContentBlock from '@/components/app/ContentBlock'

	export default {
		props: ['data'],
		components: {
			ContentBlock
		}
	}
</script>