<template>
	<div>
		<VueSlickCarousel class="photosSlider" v-if="isMobile && downloaded" v-bind="slickSettings">
			<div class="photos__item" v-for="photo in photos">
				<a :href="photo.url" target="_blank">
					<img :src="photo.image">
				</a>
			</div>
			<template #customPaging="page">
				<div class="dot2"></div>
			</template>
		</VueSlickCarousel>
		<div class="photos" v-else>
			<a class="photos__item" v-for="photo in photos" :href="photo.url" target="_blank">
				<img :src="photo.image">
			</a>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/Instagram'
</style>
<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	export default {
		data: () => ({
			photos: [],
			downloaded: false,
			slickSettings: {
				dots: true,
				arrows: false,
				dotsClass: 'photosSlider__dots',
				infinite: true,
			},
			isMobile: null
		}),
		methods: {
			setIsMobile: function(){
				if(window.innerWidth > 600){
					this.isMobile = false;
				} else{
					this.isMobile = true;
				}
			}
		},
		mounted(){
			var comp = this;
			const axios = require('axios').default;
			instagramPhotos();
			async function instagramPhotos () {
				const res = []				
				try {
					const userInfoSource = await axios.get('https://www.instagram.com/artrockets.ru/')
					const jsonObject = userInfoSource.data.match(/<script type="text\/javascript">window\._sharedData = (.*)<\/script>/)[1].slice(0, -1)

					const userInfo = JSON.parse(jsonObject)
					const mediaArray = userInfo.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges;
					var iterator = 0;
					for (let media of mediaArray) {
						const node = media.node
						if ((node.__typename && node.__typename !== 'GraphImage')) {
							continue
						}
						res.push({
							url: "https://www.instagram.com/p/" + node.shortcode,
							image: node.thumbnail_src
						})
						iterator++;
						if(iterator >= 6){
							break;
						}
					}
				} catch (e) {
					console.error('Unable to retrieve photos. Reason: ' + e.toString())
				}
				comp.photos = res;
				comp.downloaded = true;
			}

			window.addEventListener('resize', function(event){
				comp.setIsMobile();
			});
			comp.setIsMobile();
		},
		components: {
			VueSlickCarousel
		}
	}
</script>