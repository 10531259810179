<template>
	<div>
		<VueSlickCarousel class="advantagesSlider" v-if="isMobile && sliderInMobile" v-bind="slickSettings">
			<template v-for="item in data">
				<a class="advantage" v-if="item.link && item.notRouter" :href="item.link">
					<div class="advantage__up">
						<p class="advantage--upperText" v-html="item.upperText" v-if="item.upperText"></p>
						<p class="advantage--title" v-html="item.title"></p>
						<p class="advantage--text" v-html="item.text"></p>
					</div>
					<div class="advantage__down" v-if="item.bottomText">
						<a :href="item.buttonLink" class="advantage--button" v-html="item.bottomText" :data-value="item.buttonValue" :data-input="item.buttonInput" :data-form="item.form" @click="buttonClick" v-if="item.button && item.buttonValue && item.buttonInput && item.form"></a>
						<a :href="item.buttonLink" class="advantage--button" v-html="item.bottomText" v-else-if="item.button"></a>
						<p v-html="item.bottomText" :style="{'color': item.bottomTextColor}" v-else></p>
					</div>
				</a>
				<router-link class="advantage" v-else-if="item.link" :to="item.link">
					<div class="advantage__up">
						<p class="advantage--upperText" v-html="item.upperText" v-if="item.upperText"></p>
						<p class="advantage--title" v-html="item.title"></p>
						<p class="advantage--text" v-html="item.text"></p>
					</div>
					<div class="advantage__down" v-if="item.bottomText">
						<a :href="item.buttonLink" class="advantage--button" v-html="item.bottomText" :data-value="item.buttonValue" :data-input="item.buttonInput" :data-form="item.form" @click="buttonClick" v-if="item.button && item.buttonValue && item.buttonInput && item.form"></a>
						<a :href="item.buttonLink" class="advantage--button" v-html="item.bottomText" v-else-if="item.button"></a>
						<p v-html="item.bottomText" :style="{'color': item.bottomTextColor}" v-else></p>
					</div>
				</router-link>
				<div class="advantage" v-else>
					<div class="advantage__up">
						<p class="advantage--upperText" v-html="item.upperText" v-if="item.upperText"></p>
						<p class="advantage--title" v-if="item.title" v-html="item.title"></p>
						<p class="advantage--text" v-html="item.text"></p>
					</div>
					<div class="advantage__down" v-if="item.bottomText">
						<a :href="item.buttonLink" class="advantage--button" v-html="item.bottomText" :data-value="item.buttonValue" :data-input="item.buttonInput" :data-form="item.form" @click="buttonClick" v-if="item.button && item.buttonValue && item.buttonInput && item.form"></a>
						<a :href="item.buttonLink" class="advantage--button" v-html="item.bottomText" v-else-if="item.button"></a>
						<p v-html="item.bottomText" :style="{'color': item.bottomTextColor}" v-else></p>
					</div>
				</div>
			</template>
			<template #customPaging="page">
				<div class="dot2"></div>
			</template>
		</VueSlickCarousel>
		<div :class="(columns == 2) ? 'advantages--twoCol' : (columns == 1) ? 'advantages--oneCol' : 'advantages--threeCol'" v-else>
			<template v-for="item in data">
				<a class="advantage" v-if="item.link && item.notRouter" :href="item.link">
					<div class="advantage__up">
						<p class="advantage--upperText" v-html="item.upperText" v-if="item.upperText"></p>
						<p class="advantage--title" v-html="item.title"></p>
						<p class="advantage--text" v-html="item.text"></p>
					</div>
					<div class="advantage__down" v-if="item.bottomText">
						<a :href="item.buttonLink" class="advantage--button" v-html="item.bottomText" :data-value="item.buttonValue" :data-input="item.buttonInput" :data-form="item.form" @click="buttonClick" v-if="item.button && item.buttonValue && item.buttonInput && item.form"></a>
						<a :href="item.buttonLink" class="advantage--button" v-html="item.bottomText" v-else-if="item.button"></a>
						<p v-html="item.bottomText" :style="{'color': item.bottomTextColor}" v-else></p>
					</div>
				</a>
				<router-link class="advantage" v-else-if="item.link" :to="item.link">
					<div class="advantage__up">
						<p class="advantage--upperText" v-html="item.upperText" v-if="item.upperText"></p>
						<p class="advantage--title" v-html="item.title"></p>
						<p class="advantage--text" v-html="item.text"></p>
					</div>
					<div class="advantage__down" v-if="item.bottomText">
						<a :href="item.buttonLink" class="advantage--button" v-html="item.bottomText" :data-value="item.buttonValue" :data-input="item.buttonInput" :data-form="item.form" @click="buttonClick" v-if="item.button && item.buttonValue && item.buttonInput && item.form"></a>
						<a :href="item.buttonLink" class="advantage--button" v-html="item.bottomText" v-else-if="item.button"></a>
						<p v-html="item.bottomText" :style="{'color': item.bottomTextColor}" v-else></p>
					</div>
				</router-link>
				<div class="advantage" v-else>
					<div class="advantage__up">
						<p class="advantage--upperText" v-html="item.upperText" v-if="item.upperText"></p>
						<p class="advantage--title" v-if="item.title" v-html="item.title"></p>
						<p class="advantage--text" v-html="item.text"></p>
					</div>
					<div class="advantage__down" v-if="item.bottomText">
						<a :href="item.buttonLink" class="advantage--button" v-html="item.bottomText" :data-value="item.buttonValue" :data-input="item.buttonInput" :data-form="item.form" @click="buttonClick" v-if="item.button && item.buttonValue && item.buttonInput && item.form"></a>
						<a :href="item.buttonLink" class="advantage--button" v-html="item.bottomText" v-else-if="item.button"></a>
						<p v-html="item.bottomText" :style="{'color': item.bottomTextColor}" v-else></p>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>



<style lang="sass">
	@import '@/assets/sass/Advantages'
</style>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	export default {
		props: ['data', 'columns', 'sliderInMobile'],
		data: () => ({
			slickSettings: {
				dots: true,
				arrows: false,
				dotsClass: 'advantagesSlider__dots',
				infinite: true
			},
			isMobile: null
		}),
		methods: {
			setIsMobile: function(){
				if(window.innerWidth > 600){
					this.isMobile = false;
				} else{
					this.isMobile = true;
				}
			},
			buttonClick: function(el){
				var button = el.target;
				var value = button.getAttribute("data-value");
				var input = button.getAttribute("data-input");
				var formName = button.getAttribute("data-form");
				if(input && value && formName){
					var form = document.forms[formName];
					if(form.elements[input].getAttribute("type") == "hidden"){
						form.elements[input].value = value;
					}
				}
				if(formName){
					var form = document.forms[formName];
					if(form.closest('.formBlock')){
						doScrolling(form.closest('.formBlock').offsetTop, 400);
					}
				}
			}
		},
		mounted(){
			var comp = this;
			window.addEventListener('resize', function(event){
				comp.setIsMobile();
			});
			comp.setIsMobile();
		},
		components: {
			VueSlickCarousel
		}
	}
</script>