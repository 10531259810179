<template>
	<div>
		<div class="margin2 lh28" v-html="data.upperText" v-if="data.upperText"></div>
		<div class="percentageWithImages">
			<div class="percentageBlocks threeCol">
				<div v-for="item in data.percentages">
					<div class="percentageBlocks--percent">
						<AnimatedNumber :to="item.percent"></AnimatedNumber> <span class="percentageBlocks--icon">%</span>
					</div>
					<div class="percentageBlocks--desc" v-html="item.desc"></div>
					<img :src="item.image" class="show1100">
				</div>
			</div>
			<div class="siteScreens type3 hide1100 mt60">
				<img v-for="item in data.percentages" :src="item.image" :class="{'siteScreens--desktop': item.type == 'desktop', 'siteScreens--tablet': item.type == 'tablet', 'siteScreens--mobile': item.type == 'mobile'}">
			</div>
		</div>
	</div>
</template>

<style lang="sass">
	@import '@/assets/sass/PercentageBlocks'
	@import '@/assets/sass/case/SiteScreens'
	@import '@/assets/sass/case/centrSerdce/adaptiveDesign'
</style>

<script>
	import	AnimatedNumber from '@/components/app/AnimatedNumber'
	export default {
		props: ['data'],
		components: {
			AnimatedNumber
		}
	}
</script>
