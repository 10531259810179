<template>
	<div class="medarPreloader">
		<div class="medarPreloader__left">
			<p class="fs20 bold mb6" v-html="data.title"></p>
			<p class="lh28" v-html="data.desc"></p>
		</div>
		<div class="medarPreloader__right">
			<img :src="require(`@/assets/img/medar/lines.png`)" alt="">
			<div class="medarPreloader__container">
				<div class="medarPreloader__icon medarPreloader__icon--1">
					<svg id="medarPreloader_icon1" width="118" height="133" viewBox="0 0 118 133" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M117.197 39.9307V39.9308V93.0684C117.194 95.3297 116.6 97.5499 115.476 99.5057C114.352 101.461 112.738 103.084 110.797 104.209L110.796 104.21L65.2046 130.779C63.2677 131.907 61.0712 132.5 58.8356 132.5C56.6001 132.5 54.4036 131.907 52.4667 130.779L6.87558 104.21C6.87556 104.21 6.87554 104.21 6.87552 104.21C4.9387 103.081 3.32971 101.457 2.21046 99.5019C1.09118 97.5463 0.50118 95.3278 0.5 93.0692V39.9312C0.503791 37.6693 1.09824 35.4487 2.22316 33.4929C3.34812 31.537 4.96373 29.9154 6.90679 28.7909L6.90809 28.7901L52.4992 2.22102C52.4993 2.22098 52.4994 2.22094 52.4994 2.2209C54.4363 1.09338 56.6327 0.5 58.8682 0.5C61.1037 0.5 63.3001 1.09338 65.237 2.2209C65.237 2.22094 65.2371 2.22098 65.2372 2.22102L110.828 28.7901C112.765 29.9189 114.373 31.5425 115.491 33.4981C116.61 35.4537 117.198 37.6723 117.197 39.9307Z" stroke="#333"/>
						<path d="M78.0512 39.0704L78.0512 39.0704L78.0484 39.0754L58.4578 73.9826L38.8673 39.0754L38.8673 39.0754L38.8645 39.0704C37.5198 36.7375 34.8788 34.5 31.9572 34.5C28.8802 34.5 25.501 36.752 25.501 41.1346V89.347C25.501 93.2558 28.7498 96.5 32.734 96.5C36.5625 96.5 39.7081 93.2387 39.7081 89.347V65.8593L52.4889 89.8357C53.772 92.3995 56.0034 93.8215 58.4578 93.8215C61.0044 93.8215 63.2316 92.3076 64.5116 89.8382C64.512 89.8376 64.5123 89.8369 64.5127 89.8362L77.2075 65.8704V89.347C77.2075 93.2474 80.4479 96.5 84.2679 96.5C88.2521 96.5 91.501 93.2558 91.501 89.347V41.1346C91.501 36.752 88.1217 34.5 85.0448 34.5C82.1289 34.5 79.3998 36.7306 78.0512 39.0704Z" stroke="#333"/>
					</svg>
				</div>
				<div class="medarPreloader__icon medarPreloader__icon--2">
					<svg width="118" height="133" viewBox="0 0 118 133" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M117.697 93.0692V39.9308C117.698 37.5855 117.087 35.2813 115.925 33.2499C114.764 31.2185 113.093 29.5313 111.08 28.3581L65.4887 1.78891C63.4756 0.616951 61.1923 0 58.8682 0C56.544 0 54.2608 0.616951 52.2477 1.78891L6.65634 28.3581C4.63687 29.5269 2.95831 31.2119 1.78974 33.2436C0.621169 35.2753 0.00386248 37.5818 0 39.9308L0 93.0692C0.00122588 95.4149 0.613892 97.719 1.77651 99.7503C2.93913 101.782 4.61081 103.469 6.62377 104.642L52.2151 131.211C54.2282 132.383 56.5115 133 58.8356 133C61.1598 133 63.443 132.383 65.4561 131.211L111.047 104.642C113.065 103.472 114.742 101.786 115.91 99.7548C117.077 97.7233 117.693 95.4175 117.697 93.0692Z" fill="white"/>
						<path d="M107.185 97.0071L62.0814 123.659C61.6911 123.885 61.2489 124.002 60.7994 124C60.3499 123.998 59.9089 123.875 59.5209 123.646C59.1329 123.416 58.8116 123.086 58.5893 122.691C58.3671 122.295 58.2517 121.847 58.2549 121.392V13.678C58.2546 13.2139 58.3734 12.7577 58.5997 12.3541C58.8261 11.9506 59.1522 11.6136 59.546 11.3763C59.9399 11.1389 60.3879 11.0094 60.8461 11.0005C61.3043 10.9916 61.7569 11.1035 62.1595 11.3252L107.12 37.2914C107.77 37.6687 108.309 38.213 108.684 38.8692C109.058 39.5253 109.255 40.2701 109.255 41.0282V93.2901C109.26 94.0386 109.072 94.7755 108.709 95.4276C108.346 96.0797 107.821 96.6242 107.185 97.0071Z" fill="#333333"/>
						<path d="M58.416 94.0563C59.616 94.006 60.7797 93.6271 61.7825 92.9603C62.7853 92.2934 63.5893 91.3638 64.1084 90.271L77.9161 63.9844V90.0345C77.9705 91.7992 78.7035 93.4733 79.9599 94.7021C81.2163 95.931 82.8975 96.6183 84.6473 96.6183C86.3972 96.6183 88.0783 95.931 89.3347 94.7021C90.5911 93.4733 91.3241 91.7992 91.3786 90.0345V40.793C91.4115 39.9745 91.2784 39.1578 90.9876 38.3929C90.6968 37.6281 90.2543 36.9312 89.6873 36.3449C89.1202 35.7586 88.4405 35.2952 87.6898 34.9832C86.9391 34.6712 86.1332 34.517 85.3214 34.5302C82.612 34.5302 79.9872 36.6463 78.6781 38.9398L58.416 75.3665" fill="white"/>
						<path d="M58.4228 94.0572C57.2274 94.0022 56.0691 93.6211 55.0713 92.9546C54.0735 92.288 53.2735 91.3609 52.7565 90.2719L38.9488 63.9853V90.0354C38.8944 91.8001 38.1614 93.4742 36.905 94.7031C35.6485 95.9319 33.9674 96.6192 32.2176 96.6192C30.4677 96.6192 28.7866 95.9319 27.5302 94.7031C26.2738 93.4742 25.5407 91.8001 25.4863 90.0354V40.7939C25.4536 39.9776 25.5858 39.1632 25.8751 38.4001C26.1643 37.637 26.6044 36.9414 27.1687 36.3554C27.7329 35.7695 28.4094 35.3056 29.1571 34.992C29.9047 34.6784 30.7078 34.5216 31.5174 34.5311C34.2268 34.5311 36.8516 36.6472 38.1607 38.9407L58.4228 75.3674" fill="#262626"/>
						<path d="M58.4228 94.0572C57.2274 94.0022 56.0691 93.6211 55.0713 92.9546C54.0735 92.288 53.2735 91.3609 52.7565 90.2719L38.9488 63.9853V90.0354C38.8944 91.8001 38.1614 93.4742 36.905 94.7031C35.6485 95.9319 33.9674 96.6192 32.2176 96.6192C30.4677 96.6192 28.7866 95.9319 27.5302 94.7031C26.2738 93.4742 25.5407 91.8001 25.4863 90.0354V40.7939C25.4536 39.9776 25.5858 39.1632 25.8751 38.4001C26.1643 37.637 26.6044 36.9414 27.1687 36.3554C27.7329 35.7695 28.4094 35.3056 29.1571 34.992C29.9047 34.6784 30.7078 34.5216 31.5174 34.5311C34.2268 34.5311 36.8516 36.6472 38.1607 38.9407L58.4228 75.3674" fill="#333333"/>
					</svg>
				</div>
			</div>
			<div class="medarPreloader__dot_list">
				<div class="medarPreloader__dot"></div>
				<div class="medarPreloader__dot medarPreloader__dot--accent"></div>
				<div class="medarPreloader__dot"></div>
				<div class="medarPreloader__dot medarPreloader__dot--accent"></div>
				<div class="medarPreloader__dot"></div>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/case/medar/Preloader'
</style>

<script>
	import Vivus from 'vivus'

	export default {
		props: ['data'],
		mounted(){
			var medarPreloader1 = this.$el.getElementsByClassName("medarPreloader__icon--1")[0];
			var medarPreloader2 = this.$el.getElementsByClassName("medarPreloader__icon--2")[0];
			new Vivus(
				"medarPreloader_icon1", {
					duration: 80,
					type: "sync"
				},
				function() {
					medarPreloader1.classList.add("active");
					medarPreloader2.classList.add("active");
				}
			);
		},
		components: {
			Vivus
		}
	}
</script>
