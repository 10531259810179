<template>
	<div class="siteMap">
		<div class="siteMap__left">
			<ul class="siteMap__menu">
				<li v-for="firstParent in siteMap" v-if="firstParent.position == 'left'">
					<router-link :to="firstParent.link">{{firstParent.label}}</router-link>
					<ul v-if="firstParent.childrens.length">
						<li v-for="secondParent in firstParent.childrens">
							<router-link :to="secondParent.link">{{secondParent.label}}</router-link>
							<ul v-if="secondParent.childrens.length">
								<li v-for="thirdParent in secondParent.childrens">
									<router-link :to="thirdParent.link">{{thirdParent.label}}</router-link>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
		</div>
		<div class="siteMap__right">
			<ul class="siteMap__menu">
				<li v-for="firstParent in siteMap" v-if="firstParent.position == 'right'">
					<router-link :to="firstParent.link">{{firstParent.label}}</router-link>
					<ul v-if="firstParent.childrens.length">
						<li v-for="secondParent in firstParent.childrens">
							<router-link :to="secondParent.link">{{secondParent.label}}</router-link>
							<ul v-if="secondParent.childrens.length">
								<li v-for="thirdParent in secondParent.childrens">
									<router-link :to="thirdParent.link">{{thirdParent.label}}</router-link>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/SiteMap'
</style>

<script>
	export default {
		data: () => ({
			siteMap: []
		}),
		mounted(){
			var comp = this;
			
			this.$router.options.routes.forEach( function(element, index) { //Первый уровень
				if(typeof(element.siteMap) != "undefined"){
					if(typeof(element.siteMap.parent) == "undefined"){
						var el = [];
						el["label"] = element.meta.breadcrumb.label;
						el["code"] = element.name;
						el["link"] = element.path;
						el["position"] = element.siteMap.position;
						el["childrens"] = [];
						comp.siteMap.push(el);
					}
				}
			});
			
			this.$router.options.routes.forEach( function(element, index) { //Второй уровень
				if(typeof(element.siteMap) != "undefined"){
					if(typeof(element.siteMap.parent) != "undefined"){
						var parentCode = element.siteMap.parent;

						comp.siteMap.forEach( function(parent, index) {
							if(parent.code == parentCode){
								var el = [];
								el["label"] = element.meta.breadcrumb.label;
								el["code"] = element.name;
								el["link"] = element.path;
								el["childrens"] = [];
								comp.siteMap[index].childrens.push(el);
							}
						});
					}
				}
			});
			
			this.$router.options.routes.forEach( function(element, index) { //Третий уровень
				if(typeof(element.siteMap) != "undefined"){
					if(typeof(element.siteMap.parent) != "undefined"){
						var parentCode = element.siteMap.parent;
						comp.siteMap.forEach( function(secondParent, secondParentIndex) {
							secondParent.childrens.forEach( function(parent, index) {
								if(parent.code == parentCode){
									var el = [];
									el["label"] = element.meta.breadcrumb.label;
									el["code"] = element.name;
									el["link"] = element.path;
									el["childrens"] = [];
									comp.siteMap[secondParentIndex].childrens[index].childrens.push(el);
								}
							});
						});
					}
				}
			});
		}
	}
</script>