<template>
	<VueSlickCarousel class="imageSlider" v-bind="slickSettings">
		<div class="imageSlider__item" v-for="slide in data" :style="{ backgroundImage: `url('${slide.image}')` }"></div>
		<template #customPaging="page">
			<div class="dot2"></div>
		</template>
	</VueSlickCarousel>
</template>

<style lang="sass">
	@import '@/assets/sass/ImageSlider'
</style>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	export default {
		props: ['data'],
		data: () => ({
			slickSettings: {
				lazyLoad: "ondemand",
				dots: true,
				arrows: false,
				dotsClass: 'imageSlider__dots',
				infinite: true,
			}
		}),
		components: {
			VueSlickCarousel
		}
	}
</script>