<template>
	<div>
		<template v-for="block in data">
			<ImageSlider v-if="block.component == 'imageSlider'" :data="block.data" :class="block.additionalClass ? block.additionalClass : ''"></ImageSlider>
			<Advantages v-if="block.component == 'advantages'" :data="block.data" :columns="block.columns" :sliderInMobile="block.sliderInMobile"></Advantages>
		</template>
	</div>
</template>

<script>
	import	ImageSlider from '@/components/app/ImageSlider'
	import	Advantages from '@/components/app/Advantages'
	export default {
		props: ['data'],
		components: {
			ImageSlider,
			Advantages
		}
	}
</script>
