<template>
	<div>
		<vue-slider
			v-model="value"
			tooltip="none"
			:marks="marks"
			dotSize="41"
			height="3px"
			:min="min"
			:max="max"
			@dragging="dragSlider"
			class="margin"
		>
		</vue-slider>

		<InfoBlocks2 v-if="contentType == 'infoBlocks2'" :data="values" :activeElement="activeElement"></InfoBlocks2>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/Slider'
</style>

<script>
	import VueSlider from 'vue-slider-component'
	import 'vue-slider-component/theme/default.css'

	import InfoBlocks2 from '@/components/app/InfoBlocks2'

	export default {
		props: ['data', 'contentType'],
		data: () => ({
			value: 0,
			min: 0,
			max: 0,
			marks: {},
			activeElement: 0,
			values: []
		}),
		beforeMount(){
			var comp = this;
			var elements = [];
			this.data.forEach(function(item) {
				elements.push({
					value: parseInt(item.value),
					label: item.label,
					firstTitle: item.firstTitle,
					firstText: item.firstText,
					secondTitle: item.secondTitle,
					secondText: item.secondText
				});
			});
			elements.sort(function(a, b){
				var keyA = new Date(a.value),
				keyB = new Date(b.value);
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});
			this.values = elements;
			var marks = {};
			var value = 0;
			var min = 0;
			var max = 0;
			elements.forEach(function(item, key) {
				if(key == 0){
					value = item.value;
					min = item.value;
				}
				if(key == elements.length - 1){
					max = item.value;
				}
				var key = item.value;
				var label = item.label;
				Object.assign(marks, {[key]: label});
			});

			this.min = min - (max-min)*0.065;
			this.max = max + (max+min)*0.1;
			this.marks = marks;
			this.value = min;
		},
		methods: {
			dragSlider: function(value){
				var comp = this;

				if(value <= this.values[0].value){
					this.activeElement = 0;
				} else if(value >= this.values[this.values.length - 1].value){
					this.activeElement = this.values.length - 1;
				} else{
					this.values.forEach(function(item, index) {
						if(item.value <= value){
							comp.activeElement = index;
						}
					});
				}
			}
		},
		components: {
			VueSlider,
			InfoBlocks2
		}
	}
</script>