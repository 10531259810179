<template>
	<div class="chat">
		<div class="chat__item" v-for="item in data" :class="item.position">
			<div class="chat__item--name" v-html="item.name"></div>
			<div class="chat__itemContainer" :style="[{backgroundColor: item.backColor}]" :class="item.textColor">
				<p v-html="item.text"></p>
				<span class="chat__item--arrow" :style="{borderColor: item.backColor}"></span>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/Chat'
</style>

<script>
	export default {
		props: ['data'],
	}
</script>