<template>
	<div>
		<template v-for="block in data">
			<ImagesTwoCol v-if="block.component == 'imagesTwoCol'" :data="block.data" :class="block.additionalClass" :clickable="block.clickable"></ImagesTwoCol>
			<Images v-if="block.component == 'images'" :data="block.data" :class="block.additionalClass"></Images>
			<Advantages v-if="block.component == 'advantages'" :data="block.data" :columns="block.columns" :sliderInMobile="block.sliderInMobile" :class="block.additionalClass"></Advantages>
			<p v-if="block.component == 'text'" class="lh28" v-html="block.text" :class="block.additionalClass"></p>
			<div v-if="block.component == 'icon'" v-html="block.svg" :class="block.additionalClass" :style="{fontSize: block.size}"></div>
			<MedarPreloader v-if="block.component == 'medarPreloader'" :data="block.data"></MedarPreloader>
			<TextWithImages v-if="block.component == 'textWithImages'" :data="block.data" :class="block.additionalClass"></TextWithImages>
			<Youtube v-if="block.component == 'youtubeVideo'" :data="block.data" :class="block.additionalClass"></Youtube>
			<Vacancies v-if="block.component == 'vacancies'" :data="block.data" :class="block.additionalClass"></Vacancies>
			<Graph v-if="block.component == 'graph'" :data="block.data" :class="block.additionalClass"></Graph>
			<PercentageBlocks v-if="block.component == 'percentageBlocks'" :data="block.data" :class="block.additionalClass" :blocksClass="block.blocksClass"></PercentageBlocks>
			<BeforeAfterBlock v-if="block.component == 'beforeAfterBlock'" :data="block.data" :class="block.additionalClass"></BeforeAfterBlock>
			<Chat v-if="block.component == 'chat'" :data="block.data" :class="block.additionalClass"></Chat>
			<SiteScreens v-if="block.component == 'siteScreens'" :data="block.data" :class="block.additionalClass"></SiteScreens>
			<PhotoScroll 
				v-if="block.component == 'photoScroll'" 
				:data="block.data" 
				:clickable="block.clickable"
				:class="block.additionalClass"
				:title="block.title"
				:secondTitle="block.secondTitle"
				:secondDesc="block.secondDesc">
			</PhotoScroll>
			<ImageWithText 
				v-if="block.component == 'imageWithText'" 
				:data="block.data" 
				:class="block.additionalClass"
				:columns="block.columns"
				:sliderInMobile="block.sliderInMobile">
			</ImageWithText>

			<div v-if="block.component == 'textWithImageInTablet'" :class="block.additionalClass" class="textWithImageInTablet">
				<div v-html="block.text" class="lh28 textWithImageInTablet--text"></div>
				<div class="textWithImageInTablet__image">
					<img v-lazy="block.image">
				</div>
			</div>
		</template>
	</div>
</template>


<script>
	import ImagesTwoCol from '@/components/app/images/ImagesTwoCol'
	import Images from '@/components/app/images/Images'
	import Advantages from '@/components/app/Advantages'
	import MedarPreloader from '@/components/app/case/medar/Preloader'
	import TextWithImages from '@/components/app/images/TextWithImages'
	import ImageWithText from '@/components/app/images/ImageWithText'
	import Youtube from '@/components/app/Youtube'
	import PhotoScroll from '@/components/app/PhotoScroll'
	import Vacancies from '@/components/app/Vacancies'
	import Graph from '@/components/app/Graph'
	import PercentageBlocks from '@/components/app/PercentageBlocks'
	import BeforeAfterBlock from '@/components/app/BeforeAfterBlock'
	import Chat from '@/components/app/Chat'
	import SiteScreens from '@/components/app/case/SiteScreens'
	export default {
		props: ['data'],
		components: {
			ImagesTwoCol,
			Images,
			Advantages,
			MedarPreloader,
			TextWithImages,
			Youtube,
			ImageWithText,
			PhotoScroll,
			Vacancies,
			Graph,
			PercentageBlocks,
			BeforeAfterBlock,
			Chat,
			SiteScreens
		}
	}
</script>
