<template>
	<div>
		<VueSlickCarousel class="reviewsSlider" v-bind="slickSettings" v-if="slides.length > 1">
			<div class="review" v-for="slide in slides">
				<div class="review__left">
					<div class="review--text" v-html="slide.text"></div>
					<a class="redLink review--open" @click="openPopup(slide.image)" v-if="slide.image">Оригинал отзыва</a>
				</div>
				<div class="review__right">
					<p class="review--title">{{slide.title}}</p>
					<div class="author">
						<div class="author__img" v-if="slide.authorImage">
							<img v-lazy="slide.authorImageSrc">
						</div>
						<div class="author__content">
							<p class="author--name">{{slide.authorName}}</p>
							<p class="author--desc" v-html="slide.authorDesc"></p>
						</div>
					</div>
				</div>
			</div>
			<template #customPaging="page">
				<div class="dot2"></div>
			</template>
		</VueSlickCarousel>
		<div class="reviewsSlider" v-else>
			<div class="review" v-for="slide in slides">
				<div class="review__left">
					<div class="review--text" v-html="slide.text"></div>
					<a class="redLink review--open" @click="openPopup(slide.image)" v-if="slide.image">Оригинал отзыва</a>
				</div>
				<div class="review__right">
					<p class="review--title">{{slide.title}}</p>
					<div class="author">
						<div class="author__img" v-if="slide.authorImage">
							<img v-lazy="slide.authorImageSrc">
						</div>
						<div class="author__content">
							<p class="author--name">{{slide.authorName}}</p>
							<p class="author--desc" v-html="slide.authorDesc"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ImagePopup ref="imagePopup"></ImagePopup>
	</div>
</template>




<style lang="sass">
	@import '@/assets/sass/Reviews'
</style>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	import ImagePopup from '@/components/app/ImagePopup'

	export default {
		props: ['data', 'project'],
		data: () => ({
			slides: [],
			slickSettings: {
				dots: true,
				arrows: false,
				dotsClass: 'reviewsSlider__dots',
				infinite: true
			}
		}),
		methods: {
			openPopup: function(image){
				var imageSrc = require(`@/assets/img/` + image);
				this.$refs.imagePopup.openPopup(imageSrc);
			}
		},
		beforeMount(){
			this.data.forEach(function(item) {
				item.authorImageSrc = require(`@/assets/img/${item.authorImage}`);
			});

			var comp = this;
			if(this.project){
				this.data.forEach( function(slide, index) {
					if(slide.project == comp.project){
						comp.slides.push(slide);
					}
				});
			} else{
				this.slides = this.data;
			}
		},
		components: {
			VueSlickCarousel,
			ImagePopup
		}
	}
</script>