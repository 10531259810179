<template>
	<transition name="slide-fade" mode="in-out">
		<div class="infoBlocks2" v-if="animation">
			<div class="infoBlocks2__left">
				<p class="infoBlocks2--title" v-html="data[activeElement].firstTitle"></p>
				<div class="infoBlocks2--text" v-html="data[activeElement].firstText"></div>
			</div>
			<div class="infoBlocks2__right">
				<p class="infoBlocks2--title" v-html="data[activeElement].secondTitle"></p>
				<div class="infoBlocks2--text" v-html="data[activeElement].secondText"></div>
			</div>
		</div>
	</transition>
</template>


<style lang="sass">
	@import '@/assets/sass/InfoBlocks2'
</style>

<script>
	
	export default {
		props: ['data', 'activeElement'],
		data: () => ({
			animation: true
		}),
		watch: {
			'activeElement': function(){
				this.animation = !this.animation
				this.$nextTick(() => {
					this.animation = true
				})
			}
		}
	}
</script>