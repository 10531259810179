<template>
	<div>
		<div class="imagePopup" v-if="image" @click="closePopup">
			<div class="imagePopup__conatiner">
				<div class="imagePopup__content">
					<div class="imagePopup__image">
						<img class="imagePopup--img" :src="image" alt="" :style="{maxHeight: imageHeight + 'px'}">
						<a class="imagePopup--close" @click="closePopup"><span class="icon-close"></span></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass">
	@import '@/assets/sass/ImagePopup'
</style>

<script>
	export default {
		data: () => ({
			image: "",
			imageHeight: 0,
			topPosition: 0
		}),
		methods: {
			openPopup: function(image) {
				this.topPosition = document.documentElement.scrollTop;
				document.body.style.overflow = "hidden";
				document.body.style.position = "fixed";
				document.body.style.width = "100%";
				document.body.style.left = "0";
				document.body.style.top = -this.topPosition + "px";
				document.querySelector("html").style.overflow = "hidden";
				document.querySelector("html").style.position = "fixed";
				document.querySelector("html").style.width = "100%";
				document.querySelector("html").style.left = "0";

				this.image = image;
				this.setImageHeight();

				setTimeout(function(){
					var header = document.getElementsByClassName("header")[0];
					header.classList.add("smallHeader");
				});
			},
			closePopup: function(e){
				var comp = this;
				
				if((!e.target.classList.contains('imagePopup--img')) || e.target.classList.contains('imagePopup--close') || e.target.closest(".imagePopup--close")){
					this.image = "";

					setTimeout(function(){
						document.body.style.overflow = "";
						document.body.style.position = "";
						document.body.style.width = "";
						document.body.style.left = "";
						document.body.style.top = "";
						document.querySelector("html").style.overflow = "";
						document.querySelector("html").style.position = "";
						document.querySelector("html").style.width = "";
						document.querySelector("html").style.left = "";
						setTimeout(window.scrollTo(0, comp.topPosition),1);
					}, 50);
				}
			},
			setImageHeight: function(){
				var comp = this;
				if(comp.$el && comp.$el.getElementsByClassName("imagePopup").length){
					var image = comp.$el.getElementsByClassName("imagePopup--img")[0];
					var height = comp.$el.getElementsByClassName("imagePopup")[0].offsetHeight - 80;
					image.style.maxHeight = height + "px";
				}
			}
		},
		watch: {
			'image': function(){
				var comp = this;
				setTimeout(function(){
					comp.setImageHeight();
				});				
			}
		},
		mounted(){
			var comp = this;
			window.addEventListener('resize', function(event){
				comp.setImageHeight();
			});
			comp.setImageHeight();
		},
	}
</script>