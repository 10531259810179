<template>
	<div>
		<p class="fs20 bold" :class="{margin4: !data.topText, mb6: data.topText}" v-html="data.title" v-if="data.title"></p>
		<p v-html="data.topText" class="margin4 lh28"></p>
		<div class="design" :class="{margin4: data.bottomText}">
			<div class="design__item">
				<div class="design__item__content">
					<div class="design__item--withVideo" v-if="data.resolutions[0].video">
						<img :src="data.resolutions[0].image" alt="" class="w100">
						<div class="videoContainer" v-if="data.resolutions[0].video">
							<video :src="data.resolutions[0].video" muted="" autoplay="" playsinline="" loop=""></video>
						</div>
					</div>
					<img v-lazy="data.resolutions[0].image" alt="" v-else>
					<p v-html="data.resolutions[0].desc"></p>
				</div>
			</div>
			<div class="design--twoCol design__item">
				<div class="design__item">
					<div class="design__item__content">
						<img v-lazy="data.resolutions[1].image" alt="">
						<p v-html="data.resolutions[1].desc"></p>
					</div>
				</div>
				<div class="design__item">
					<div class="design__item__content">
						<img v-lazy="data.resolutions[2].image" alt="">
						<p v-html="data.resolutions[2].desc"></p>
					</div>
				</div>
			</div>
		</div>
		<p v-html="data.bottomText" class="lh28"></p>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/case/Design'
</style>

<script>
	export default {
		props: ['data'],
		methods: {
			videoHeight: function(){
				var comp = this;
				var videoContainers = comp.$el.getElementsByClassName("design__item--withVideo");
				
				Array.from(videoContainers).forEach(function(el, index){
					var video = el.getElementsByTagName("video")[0];
					video.style.height = 'auto';
					video.style.width = 'auto';

					var containerHeight = el.offsetHeight;
					var containerWidth = el.offsetWidth;
					var videoHeight = video.offsetHeight;
					var videoWidth = video.offsetWidth;

					if(containerHeight/containerWidth > containerWidth/videoWidth){
						video.style.height = (containerHeight+10) + "px";
					} else{
						video.style.width = (containerWidth+10) + "px";
					}
				});
			}
		},
		mounted() {
			var comp = this;

			this.videoHeight();

			setTimeout(function(){
				comp.videoHeight();
			}, 1000);

			window.addEventListener('resize', function(event){
				comp.videoHeight();
			});
		}
	}
</script>
