<template>
	<div :class="additionalClass?additionalClass:''">
		<div class="aboutUsWithNumbers">
			<div class="aboutUsWithNumbers__item" v-for="item in data">
				<p class="aboutUsWithNumbers--title" v-html="item.title"></p>
				<p class="aboutUsWithNumbers--text" v-html="item.text"></p>
			</div>
		</div>
	</div>
</template>

<style lang="sass">
	@import '@/assets/sass/AboutUsInNumbers'
</style>

<script>
	export default {
		props: ['data', 'additionalClass'],
	}
</script>