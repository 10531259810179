<template>
	<div class="blockLeftMenu" :class="additionalClass">
		<div class="blockLeftMenu__item" v-for="block in blocks" :style="{background: block.background}" :class="[block.additionalClass, {noTopPadding: block.noTopPadding, noBottomPadding: block.noBottomPadding, noBorder: block.noBorder}]">
			<div class="container">
				<div class="blockLeftMenu__left" :class="block.nameType">
					<div class="blockLeftMenu__left__content">
						<router-link :to="block.link" v-if="block.link" v-html="block.name"></router-link>
						<p v-html="block.name" v-else></p>

						<p class="blockLeftMenu--desc" v-html="block.desc" v-if="block.desc"></p>

						<a :href="block.linkHref" :target="block.linkTarget" rel="nofollow" v-if="block.linkHref && block.linkText && block.linkTarget == '_blank'" v-html="block.linkText" class="blockLeftMenu--link"></a>
					</div>		
				</div>
				<div class="blockLeftMenu__right">
					<Projects 
						v-if="block.component == 'projects'" 
						:data="block.data" 
						:quantity="block.quantity" 
						:quantityTable="block.quantityTable" 
						:quantityMobile="block.quantityMobile" 
						:category="block.category" 
						:showPagination="block.showPagination ? block.showPagination : false"
						:smallElements="block.smallElements"
						:type="block.type"
						:support="block.support"
						:cms="block.cms">
					</Projects>

					<Advantages v-if="block.component == 'advantages'" :data="block.data" :columns="block.columns" :sliderInMobile="block.sliderInMobile"></Advantages>
					<Features v-if="block.component == 'features'" :data="block.data"></Features>
					<AboutUs v-if="block.component == 'aboutUs'" :data="block.data"></AboutUs>
					<Reviews v-if="block.component == 'reviews'" :data="block.data" :project="block.project"></Reviews>
					<Development v-if="block.component == 'development'" :data="block.data"></Development>
					<Vacancies v-if="block.component == 'vacancies'" :data="block.data"></Vacancies>
					<Slider v-if="block.component == 'slider'" :data="block.data" :contentType="block.contentType"></Slider>
					<List v-if="block.component == 'list'" :data="block.data" :listType="block.listType"></List>
					<Command v-if="block.component == 'command'" :data="block.data"></Command>
					<Instagram v-if="block.component == 'instagram'"></Instagram>
					<SiteMap v-if="block.component == 'siteMap'"></SiteMap>
					<CaseInfo v-if="block.component == 'caseInfo'" :data="block.data"></CaseInfo>
					<Design v-if="block.component == 'design'" :data="block.data"></Design>
					<ContentBlock v-if="block.component == 'contentBlock'" :data="block.data"></ContentBlock>
					<CentrSerdceAdaptiveDesign v-if="block.component == 'centrSerdceAdaptiveDesign'" :data="block.data"></CentrSerdceAdaptiveDesign>
					<ContentBlockWithImage v-if="block.component == 'contentBlockWithImage'" :data="block.data" :class="block.additionalClass"></ContentBlockWithImage>

					<PhotoScroll v-if="block.component == 'photoScroll'" :data="block.data" :clickable="block.clickable"></PhotoScroll>
					<PhotoScrollBlocks v-if="block.component == 'photoScrollBlocks'" :data="block.data" :showQuantity="block.showQuantity" :clickable="block.clickable"></PhotoScrollBlocks>

					<div class="dropDowns" v-if="block.component == 'dropDown'" :class="{'type2': block.type == '2'}">
						<DropDown v-for="item in block.data" :title="item.title" :desc="item.desc" :content="item.content" :showHideButton="block.showHideButton"></DropDown>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/WithLeftMenu'
</style>

<script>
	var leftMenuItems = null;
	function leftMenuItemsPosition(){
		leftMenuItems.forEach(item => {
			var parent = item.closest(".blockLeftMenu__left");
			var parentTop = parent.offsetTop;
			var parentLeft = parent.offsetLeft;
			var parentHeight = parent.offsetHeight;
			var parentWidth = parent.offsetWidth;
			var itemTop = item.offsetTop;
			var scrollTop = window.pageYOffset;

			var windowWidth  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

			var top = 28;

			if(windowWidth < 1440){
				top = 70;
			}

			if(scrollTop + item.offsetHeight >= parentTop + parentHeight - top){
				item.style.top = "auto";
				item.style.bottom = "0px";
				item.style.position = "absolute";
				item.style.width = parentWidth + "px";
				item.style.left = "";
			} else if(scrollTop + top < parentTop){
				item.style.top = "";
				item.style.bottom = "";
				item.style.position = "";
				item.style.width = "";
				item.style.left = "";
			} else {
				item.style.top = top + "px";
				item.style.bottom = "auto";
				item.style.position = "fixed";
				item.style.width = parentWidth + "px";
				item.style.left = parentLeft + "px";
			}
		})
	}
	window.onscroll = function() {
		leftMenuItemsPosition();
	}
	window.onresize = function() {
		leftMenuItemsPosition();
	}

	import Projects from '@/components/app/Projects'
	import Advantages from '@/components/app/Advantages'
	import Features from '@/components/app/Features'
	import AboutUs from '@/components/app/AboutUs'
	import Reviews from '@/components/app/Reviews'
	import Development from '@/components/app/Development'
	import Vacancies from '@/components/app/Vacancies'
	import Slider from '@/components/app/Slider'
	import DropDown from '@/components/app/DropDown'
	import List from '@/components/app/List'
	import Command from '@/components/app/Command'
	import Instagram from '@/components/app/Instagram'
	import SiteMap from '@/components/app/SiteMap'
	import CaseInfo from '@/components/app/case/CaseInfo'
	import PhotoScroll from '@/components/app/PhotoScroll'
	import Design from '@/components/app/case/Design'
	import ContentBlock from '@/components/app/ContentBlock'
	import ContentBlockWithImage from '@/components/app/ContentBlockWithImage'
	import PhotoScrollBlocks from '@/components/app/PhotoScrollBlocks'
	import CentrSerdceAdaptiveDesign from '@/components/app/case/centrSerdce/adaptiveDesign'

	export default {
		props: ['blocks', 'additionalClass'],
		components: {
			Projects,
			Advantages,
			Features,
			AboutUs,
			Reviews,
			Development,
			Vacancies,
			Slider,
			DropDown,
			List,
			Command,
			Instagram,
			SiteMap,
			CaseInfo,
			PhotoScroll,
			Design,
			ContentBlock,
			ContentBlockWithImage,
			PhotoScrollBlocks,
			CentrSerdceAdaptiveDesign
		},
		mounted(){
			leftMenuItems = document.querySelectorAll('.blockLeftMenu__left__content');
		}
	}
</script>