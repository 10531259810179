<template>
	<div class="featuresContainer">
		<VueSlickCarousel class="featuresSlider" v-bind="slickSettings">
			<div class="feature" v-for="slide in data">
				<div class="feature__content">
					<p class="feature--title" v-html="slide.title"></p>
					<p class="feature--text" v-html="slide.text"></p>
				</div>
				<div class="feature__img" :class="slide.imageClass">
					<img :src="slide.imageSrc" alt="">
				</div>
			</div>
			<template #customPaging="page">
				<div class="dot2"></div>
			</template>
		</VueSlickCarousel>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/Features'
</style>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	export default {
		props: ['data'],
		data: () => ({
			slickSettings: {
				dots: true,
				arrows: false,
				dotsClass: 'featuresSlider__dots',
				infinite: true
			},
			isPause: false,
			percentTime: 0,
			time: 5,
			tick: null,
			dotsProgress: null,
		}),
		beforeMount(){
			this.data.forEach(function(item) {
				item.imageSrc = require(`@/assets/img/${item.image}`);
			});
		},
		components: {
			VueSlickCarousel
		}
	}
</script>