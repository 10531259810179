<template>
	<transition name="slide">
		<div class="formBlock setMenuColor oldForm" data-menu-color="light" id="form" v-if="success" key="success">
			<div class="container">
				<div class="formBlock__left">
					<p>Обсудить проект</p>
				</div>
				<div class="formBlock__right">
					<div class="formBlockSuccess">
						<div class="formBlockSuccess__left">
							<div class="formBlockSuccess__header">
								<p class="formBlockSuccess--title">Спасибо за обращение!</p>
								<p class="formBlockSuccess--desc"> Мы скоро свяжемся с Вами</p>
							</div>
							<a class="openForm" @click="openForm">Отправить еще раз</a>
						</div>
						<div class="formBlockSuccess__right">
							<img :src="require(`@/assets/img/success.png`)" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="formBlock setMenuColor oldForm" data-menu-color="light" id="form" v-else key="form">
			<div class="container">
				<div class="formBlock__left">
					<p>Обсудить проект</p>
				</div>
				<div class="formBlock__right">
					<div class="formBlock__header">
						<p class="formBlock--title">Заполните форму, и мы вам перезвоним</p>
					</div>
					<form class="form" name="feedback" autocomplete="off">
						<input type="hidden" v-for="input in hiddenInputesArray" :name="input">
						<input type="hidden" v-model.lazy="formValues.testBot">
						<div class="form__item--throuble">
							<div class="form__item">
								<p class="form__item--title">Ваше имя</p>
								<input type="text" class="required" placeholder="Константин" data-mask="singlename" v-model.lazy="formValues.name" autocomplete="nope">
								<span class="validation-error"></span>
							</div>
							<div class="form__item">
								<p class="form__item--title">Телефон</p>
								<input type="tel" inputmode="numeric" class="required" placeholder="+7 987 654 32 10" data-mask="phone" v-model.lazy="formValues.phone" autocomplete="nope">
								<span class="validation-error"></span>
							</div>
							<div class="form__item">
								<p class="form__item--title">E-mail</p>
								<input type="text" class="required" placeholder="mail@mail.ru" data-mask="email" v-model.lazy="formValues.email" autocomplete="nope">
								<span class="validation-error"></span>
							</div>
						</div>
						<div class="form__item">
							<p class="form__item--title">Прикрепить ТЗ</p>
							<div class="fileInput">
								<p class="fileInput--text hide600">Добавьте документ или <span>выберите вручную</span></p>
								<p class="fileInput--text show600">Добавьте документ</p>
								<input type="file" name="file" data-mask="file" autocomplete="off">
							</div>
							<span class="validation-error"></span>
						</div>
						<div class="form__button buttonWithText">
							<a class="button button--red submit" @click="submit">Отправить заявку</a>
							<p class="fs13">
								Отправляя заявку, вы соглашаетесь с<br> политикой конфиденциальности
							</p>
						</div>
					</form>
				</div>
			</div>
		</div>
	</transition>
</template>



<style lang="sass">
	@import '@/assets/sass/forms/mainForm'
</style>

<script>
	export default {
		props: ['hiddenInputes'],
		data: () => ({
			success: false,
			hiddenInputesArray: [],
			formValues: {
				formName: 'feedback',
				name: '',
				phone: '',
				email: '',
				page: '',
				fileName: '',
				testBot: ''
			}
		}),
		beforeMount(){
			if(this.hiddenInputes){
				this.hiddenInputesArray = this.hiddenInputes.split(",");
			}
		},
		mounted(){
			this.$el.querySelectorAll('input').forEach(input => {
				initValidator(input);
			});
		},
		methods: {
			submit: function(e){
				var comp = this;
				e.preventDefault();
				var form = e.toElement.closest("form");
				var inputes = form.getElementsByTagName("input");
				inputes.forEach(function(input) {
					if(input.offsetWidth > 0 && input.offsetHeight > 0){
						validate(input);
					}
				});

				if(form.elements["file"].value){
					this.formValues.fileName = form.elements["file"].files[0].name;
				}
				
				this.formValues.page = this.$router.app._route.path;


				form.elements.forEach(function(input){
					if(input.getAttribute("type") == "hidden"){
						comp.formValues[input.getAttribute("name")] = input.value;
					}
				});
				console.log(this.formValues);


				if(!form.getElementsByClassName("error").length){
					var data = new FormData();
					data.append("json", JSON.stringify(this.formValues));

					const request = new XMLHttpRequest();
					const url = "/local/include/ajax/feedback.php";

					request.responseType = "json";
					request.open("POST", url);
					
					request.addEventListener("readystatechange", () => {
 
						if (request.readyState === 4 && request.status === 200) {
							let obj = request.response;
							comp.success = true;
							setTimeout(function(){
								doScrolling(e.toElement.closest(".formBlock").offsetTop, 400);
							}, 400);
						}
					});
					 
					request.send(data);
				}
			},
			openForm: function(e){
				var comp = this;
				var form = e.toElement.closest(".formBlock");
				var inputes = form.getElementsByTagName("input");
				setTimeout(function(){
					inputes.forEach(function(input) {
						if(input.attributes.type.value == "text"){
							input.value = "";
							input.closest('.form__item').classList.remove("error");
						} else if(input.attributes.type.value == "file"){
							if(input.closest(".fileInput").getElementsByClassName("fileInputFile--delete").length){
								input.closest(".fileInput").getElementsByClassName("fileInputFile--delete")[0].click();
							}
						}
					});
				});

				this.formValues.name = '';
				this.formValues.phone = '';
				this.formValues.email = '';
				this.formValues.page = '';
				this.formValues.fileName = '';

				this.success = false;
				setTimeout(function(){
					comp.$el.querySelectorAll('input').forEach(input => {
						initValidator(input);
						doScrolling(e.toElement.closest(".formBlock").offsetTop, 400);
					});
				}, 400);
			}
		}
	}
</script>