<template>
	<div class="beforeAfterBlock" :class="data.type">
		<div class="beforeAfterBlock__before" :style="{width: percent + '%'}">
			<img :src="data.beforeImage" alt="" :style="{width: imageWidth + 'px'}">
		</div>
		<div class="beforeAfterBlock__after">
			<img :src="data.afterImage" alt="" :style="{width: imageWidth + 'px'}">
		</div>
		<vue-slider
			v-model="value"
			tooltip="none"
			dotSize="41"
			height="0"
			:min="min"
			:max="max"
			@dragging="dragSlider"
			class="beforeAfterBlock--slider"
		>
		</vue-slider>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/BeforeAfterBlock'
</style>

<script>
	import VueSlider from 'vue-slider-component'
	import 'vue-slider-component/theme/default.css'

	export default {
		props: ['data'],
		data: () => ({
			value: 500,
			min: 0,
			max: 1000,
			imageWidth: 0,
			percent: 50
		}),
		methods: {
			setImagesSize: function(){
				var width = this.$el.offsetWidth;
				this.imageWidth = width;
			},
			dragSlider: function(value){
				this.percent = value / 10;
			}
		},
		mounted(){
			var comp = this;
			window.addEventListener('resize', function(event){
				comp.setImagesSize();
			});
			comp.setImagesSize();
		},
		components: {
			VueSlider
		}
	}
</script>
