<template>
	<div>
		<VueSlickCarousel class="commandSlider" v-if="isMobile" v-bind="slickSettings">
			<div class="command__item" v-for="person in data">
				<div class="command__img" v-lazy:background-image="person.image"></div>
				<p class="command--name" v-html="person.name"></p>
				<p class="command--position" v-html="person.position"></p>
			</div>
			<div class="command__item" v-if="vacanciesQuantity">
				<div class="command__img">
					<span class="icon-plus"></span>
				</div>
				<p class="command--name">{{vacanciesQuantity}} {{vacanciesQuantity | num2str(['вакансия', 'вакансии', 'вакансий'])}}</p>
				<router-link to="/vacancies/" class="command--position redText">Стать частью команды</router-link>
			</div>
			<template #customPaging="page">
				<div class="dot2"></div>
			</template>
		</VueSlickCarousel>
		<div class="command" v-else>
			<div class="command__item" v-for="person in data">
				<div class="command__img" v-lazy:background-image="person.image"></div>
				<p class="command--name" v-html="person.name"></p>
				<p class="command--position" v-html="person.position"></p>
			</div>
			<div class="command__item" v-if="vacanciesQuantity">
				<router-link to="/vacancies/" class="command__img">
					<span class="icon-plus"></span>
				</router-link>
				<p class="command--name">{{vacanciesQuantity}} {{vacanciesQuantity | num2str(['вакансия', 'вакансии', 'вакансий'])}}</p>
				<router-link to="/vacancies/" class="command--position redText">Стать частью команды</router-link>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/Command'
</style>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	import vacanciesData from "@/assets/json/vacancies.json";

	export default {
		props: ['data'],
		data: () => ({
			vacanciesQuantity: 0,
			slickSettings: {
				dots: true,
				arrows: false,
				dotsClass: 'commandSlider__dots',
				infinite: true
			},
			isMobile: null
		}),
		methods: {
			setIsMobile: function(){
				if(window.innerWidth > 600){
					this.isMobile = false;
				} else{
					this.isMobile = true;
				}
			}
		},
		mounted(){
			var comp = this;
			window.addEventListener('resize', function(event){
				comp.setIsMobile();
			});
			comp.setIsMobile();

			this.vacanciesQuantity = vacanciesData.length;
		},
		filters: {
			num2str: function (n, text_forms) {
				n = Math.abs(n) % 100;
				var n1 = n % 10;

				if (n > 10 && n < 20) {
					return text_forms[2];
				}

				if (n1 > 1 && n1 < 5) {
					return text_forms[1];
				}

				if (n1 == 1) {
					return text_forms[0];
				}

				return text_forms[2];
			}
		},
		components: {
			VueSlickCarousel
		}
	}
</script>
