<template>
	<div class="textWithImages">
		<div class="textWithImages__text">
			<p class="fs20 bold" :class="{margin4: !data.desc, mb6: data.desc}" v-html="data.title" v-if="data.title"></p>
			<p class="lh28" v-html="data.desc" v-if="data.desc"></p>
		</div>
		<div class="textWithImages__images" :class="{twoCol: data.imageCol == 2}">
			<img v-for="image in data.images" v-lazy="image.image">
		</div>
	</div>
</template>

<style lang="sass">
	@import '@/assets/sass/images/TextWithImages'
</style>

<script>
	export default {
		props: ['data'],
	}
</script>