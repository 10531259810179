<template>
	<span>
		{{num}}
	</span>
</template>


<script>
	export default {
		props: ['to'],
		data: () => ({
			num: 0,
			finish: 0
		}),
		methods: {
			onScroll(e) {
				var comp = this;
				if (comp.$el.offsetTop + comp.$el.offsetHeight < window.scrollY + window.innerHeight) {
					comp.removeScrollHandler();
					const interval = setInterval(function() {
						if (comp.num + 1  >= comp.finish) {
							clearInterval(interval);
							comp.num = comp.finish
						} else{
							comp.num += 1;
							comp.num = parseFloat(comp.num.toFixed(2));
						}
					}, 1500 / comp.finish);
				}
			},
			removeScrollHandler() {
				var comp = this;
				window.removeEventListener('scroll', comp.onScroll);
			},
		},
		mounted() {
			var comp = this;
			comp.finish = comp.to;
			comp.finish = comp.finish.replace(",", ".");
			comp.finish = parseFloat(comp.finish);
			window.addEventListener('scroll', comp.onScroll);
			comp.$on('hook:beforeDestroy', comp.removeScrollHandler);
			comp.onScroll();
		},
	}
</script>
